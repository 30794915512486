<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Tambah Farmasi Baru</strong>
        </h5>
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Nama Farmasi"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Nama Farmasi"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: 'Nama farmasi harus diisi!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nomor KTP">
            <a-input
              :disabled="submitting"
              placeholder="Nomor KTP"
              v-decorator="['idNumber', { rules: [{required: true, len: 16, message: 'Nomor KTP harus 16 digit!' }]}]"
            />
          </a-form-item> -->
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="E-mail"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan E-mail"
              v-decorator="[
                'email',
                { rules: [{ required: true, message: 'E-mail harus diisi!' }] },
              ]"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Alamat"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Alamat"
              v-decorator="[
                'address',
                {
                  rules: [
                    { required: true, message: 'Alamat farmasi harus diisi!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kota"
          >
            <a-select
              :disabled="submitting"
              v-decorator="[
                'city',
                { rules: [{ required: true, message: 'Kota harus diisi!' }] },
              ]"
              placeholder="Kota"
            >
              <a-select-option
                v-for="city in cityList"
                :key="city.id"
                :value="city.name"
              >
                {{ city.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Sertifikat"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'certificate',
                {
                  valuePropName: 'certificate',
                },
              ]"
              name="upload"
              :action="action"
              list-type="picture"
              :file-list="fileList"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
          <button
            type="submit"
            class="btn btn-success px-5"
            :disabled="submitting"
          >
            Simpan Data
          </button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import { create } from '@/services/axios/api/farmasi'
import { getAll } from '@/services/axios/api/city'
import { notification } from 'ant-design-vue'
import { BASE_URL } from '@/config'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      submitting: false,
      // action: `${BASE_URL}/upload/certificate`,
      action: `${BASE_URL}/upload/certificate`,
      fileList: [],
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            if (values.certificate) {
              values.certificate = values.certificate.file.response.data
            }
            const res = await create(values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              router.push('/farmasi')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    handleChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
    },
  },
}
</script>
