var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Nama Farmasi"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  { required: true, message: 'Nama farmasi harus diisi!' } ],
              } ]),expression:"[\n              'name',\n              {\n                rules: [\n                  { required: true, message: 'Nama farmasi harus diisi!' },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Masukkan Nama Farmasi"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"E-mail"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              { rules: [{ required: true, message: 'E-mail harus diisi!' }] } ]),expression:"[\n              'email',\n              { rules: [{ required: true, message: 'E-mail harus diisi!' }] },\n            ]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Masukkan E-mail"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Alamat"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address',
              {
                rules: [
                  { required: true, message: 'Alamat farmasi harus diisi!' } ],
              } ]),expression:"[\n              'address',\n              {\n                rules: [\n                  { required: true, message: 'Alamat farmasi harus diisi!' },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Masukkan Alamat"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Kota"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'city',
              { rules: [{ required: true, message: 'Kota harus diisi!' }] } ]),expression:"[\n              'city',\n              { rules: [{ required: true, message: 'Kota harus diisi!' }] },\n            ]"}],attrs:{"disabled":_vm.submitting,"placeholder":"Kota"}},_vm._l((_vm.cityList),function(city){return _c('a-select-option',{key:city.id,attrs:{"value":city.name}},[_vm._v(" "+_vm._s(city.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label":"Sertifikat"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'certificate',
              {
                valuePropName: 'certificate',
              } ]),expression:"[\n              'certificate',\n              {\n                valuePropName: 'certificate',\n              },\n            ]"}],attrs:{"multiple":false,"name":"upload","action":_vm.action,"list-type":"picture","file-list":_vm.fileList},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to upload ")],1)],1)],1),_c('button',{staticClass:"btn btn-success px-5",attrs:{"type":"submit","disabled":_vm.submitting}},[_vm._v(" Simpan Data ")])],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"mb-4"},[_c('strong',[_vm._v("Tambah Farmasi Baru")])])}]

export { render, staticRenderFns }